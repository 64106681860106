<template>
  <div>
    <v-row>
      <v-col align="center" justify="center">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          outlined
          clearable
          @keydown.enter="inputSearch"
        ></v-text-field>
        <p
          v-for="result in history"
          :key="result.id"
          @click="previousSearch(result.value)"
        >
          {{ result.value }}
        </p>
        <v-btn
          class="clear-search-btn"
          text
          @click="clearHistory"
          color="orange"
        >
          {{ $t("search.clearSearch") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      search: ""
    };
  },
  computed: {
    history() {
      return this.$store.getters["history/getHistory"];
    }
  },
  methods: {
    inputSearch() {
      var newSearch = {
        id: this.history.length,
        value: this.search
      };

      this.$store.commit("history/addToHistory", newSearch);
    },
    clearHistory() {
      this.$store.commit("history/clearHistory");
    },
    previousSearch(previousSearch) {
      this.search = previousSearch;
      this.inputSearch();
    }
  }
};
</script>
<style>
.clear-search-btn {
  width: 135px;
  height: 20px;

  font-family: Lora !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 137% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;
}
</style>
